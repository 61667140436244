const isBrowser = typeof window !== `undefined`

//export const setUser = user => (window.localStorage.gatsbyUser = JSON.stringify(user))
export const setUser = user => (window.localStorage.gatsbyUser = storeUser(user))
export const setCard = card => (window.localStorage.gatsbyCard = storeUser(card))

function storeUser (gUser)
{
	console.log(gUser)
	
  return JSON.stringify(gUser)
	
}

const getUser = () => {
  if (window.localStorage.gatsbyUser) {
    let user = JSON.parse(window.localStorage.gatsbyUser)
    return user ? user : {}
  }
  return {}
}

const getCard= () => {
  if (window.localStorage.gatsbyCard) {
    let card = JSON.parse(window.localStorage.gatsbyCard)
    return card ? card : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  if (user) return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()
export const getCurrentCard = () => isBrowser && getCard()

export const logout = callback => {
  if (!isBrowser) return
  setUser({})
  setCard({})
  callback()
}
