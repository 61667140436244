/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import Amplify, { Auth } from 'aws-amplify';
import { setUser } from './src/util/auth'
import { setCard } from './src/util/auth'
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from './src/graphql/queries';
import awsConfig from './src/util/scoop-Setting';
import isLocalhost from './src/util/localHost'



// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectURI,
  productionRedirectURI,
] = awsConfig.oauth.redirectURI.split(",");


const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
	redirectURI: isLocalhost ? localRedirectURI : productionRedirectURI,
    redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);



export const onRouteUpdate = (state, page, pages) => {
	
	 
		console.log("page " + state.location.pathname);
		 Auth.currentSession().then (session => { console.log(session) 
			
	   }).catch(err => { console.log('err x' + err) 
			
	   })
	   
  Auth.currentAuthenticatedUser()
    .then(user => {
      const userInfo = {
		 ...user.attributes, 
		 
        username: user.username,
		name : user.signInUserSession.idToken.payload.name,
		email : user.signInUserSession.idToken.payload.email
      }
	  console.log("user " + user.username);
      setUser(userInfo)
	  
		if (state.location.pathname='/app/profile')
      {
		  //Auth.configure(awsconfig1);
	   API.graphql(graphqlOperation(queries.retrieveUserByUsername, { userName : user.username})).then(
	       dets => setCard(dets.data.retrieveUserByUsername)).catch (err => console.log(err))
      }
	})
	  
    .catch(err => {
      window.localStorage.setItem('gatsbyUser', null)
	    console.log("err " + err);
    })
	
	
}