/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const retrieveUserDetails = /* GraphQL */ `
  query RetrieveUserDetails($cardNum: String) {
    retrieveUserDetails(cardNum: $cardNum)
  }
`;
export const retrieveUserByUsername = /* GraphQL */ `
  query RetrieveUserByUsername($userName: String) {
    retrieveUserByUsername(userName: $userName)
  }
`;
