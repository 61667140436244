/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    //"aws_cognito_identity_pool_id": "ap-southeast-2:793ad637-7292-4011-a4f2-a1545c9f5e66",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_DvRgGGxJa",
    "aws_user_pools_web_client_id": "l46mvuqovrnvp2bpcqjtdj8th",
    "oauth": {
		
		"domain": "scoopandsmilenz.auth.ap-southeast-2.amazoncognito.com",
	
		"clientID": 'l46mvuqovrnvp2bpcqjtdj8th',
        "scope": [
            
            "email",
            "openid", 
            "profile"
            
        ],
		 
        
		"redirectSignIn": "http://localhost:8000,https://master.d2im9geumnfmqi.amplifyapp.com/",
		"redirectURI": "http://localhost:8000,https://master.d2im9geumnfmqi.amplifyapp.com/",
		
        "redirectSignOut": "http://localhost:8000,https://master.d2im9geumnfmqi.amplifyapp.com",
		"responseType": "token"
	},
    "aws_appsync_graphqlEndpoint": "https://xjrulzv7jjgn3b73zralo3hgsq.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7k67gfg5sfgfraknzr7iqxdpxe"
};


export default awsmobile;
